<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchForm"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.full_name')" prop="pdaManagerName">
                <el-input
                  :maxlength="20"
                  v-model.trim="formInline.pdaManagerName"
                  placeholder="姓名"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Employee_ID')" prop="pdaManagerCode">
                <el-input
                  :maxlength="30"
                  v-model.trim="formInline.pdaManagerCode"
                  placeholder="员工编号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.role')" prop="roleId">
                <el-select v-model.trim="formInline.roleId" size="12">
                  <el-option label="全部" value=""></el-option>
                  <el-option :label="$t('searchModule.Toll_collector')" value="1"></el-option>
                  <el-option :label="$t('searchModule.Inspector')" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Date_search')" prop="dateType">
                <el-select
                  v-model.trim="formInline.dateType"
                  placeholder="请选择"
                  style="width:100px; margin:0 7px 0 0"
                  @change="timeTypeChange"
                >
                  <el-option label="日" value="1"></el-option>
                  <el-option label="月" value="2"></el-option>
                </el-select>
                <el-date-picker
                  v-model="searchTime"
                  @change="searchTimeChange"
                  :clearable="false"
                  :type="timeType"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions"
                      >
                </el-date-picker>
              </el-form-item> -->
              <el-form-item :label="$t('searchModule.Date_search')" prop="dateType">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defaultRange="defaultRangeValue"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                @click="
                  page = 1;
                  searchData();
                "
              >
                查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="clearForm">
                清空
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-upload2" @click="exportFile">
                导出
              </el-button>
            </div>
          </div>
          <tabCard :cardLists="cardLists"></tabCard>
        </el-form>
        <!-- <div>
          <div class="totalNum">
            <div>应到岗</div>
            <div>
              <span>{{ attendanceTotal.sumScheduleCount }}</span
              >人
            </div>
          </div>
          <div class="totalNum">
            <div>打卡</div>
            <div>
              <span>{{ attendanceTotal.sumAttendTypeOn }}</span
              >人
            </div>
          </div>
          <div class="totalNum">
            <div>未打卡</div>
            <div>
              <span>{{ attendanceTotal.sumLackDay }}</span
              >人
            </div>
          </div>
          <div class="totalNum">
            <div>迟到/早退</div>
            <div>
              <span>{{ attendanceTotal.sumExceptDay }}</span
              >人
            </div>
          </div>
        </div> -->
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @sort-change="handleSortChange"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('list.Should_be_on_duty')"
            min-width="140px"
            align="center"
            prop="scheduleCount"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.scheduleCount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.Normal_on_duty')"
            min-width="120px"
            align="center"
            prop="normalDay"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.normalDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.Late_or_Early_Departure')"
            min-width="160px"
            align="center"
            prop="exceptDay"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.exceptDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.clock_in')"
            min-width="120px"
            align="center"
            prop="attendTypeOn"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.attendTypeOn }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.Not_clocking_in')"
            min-width="140px"
            align="center"
            prop="lackDay"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.lackDay }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column
            label="操作"
            width="90"
            align="center"
            v-if="$route.meta.authority.button.view"
          >-->
          <el-table-column
            :label="$t('list.operation')"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="padding: 0"
                @click="
                  $router.push({
                    path: '/userAttendanceDetails',
                    query: {
                      ...scope.row,
                      dateType: formInline.dateType,
                      startTime: formInline.startDate,
                      endTime: formInline.endDate,
                    },
                  })
                "
                >查看详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-if="total != 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// exportExcelNew
import { dateFormat, exportExcelNew } from "@/common/js/public";
import timeRangePick from "@/components/timePicker";
import tabCard from "@/components/tabCard/index.vue";
export default {
  name: "collectorAttence",
  data() {
    let today = new Date(new Date().getTime());
    let yesterday = new Date(new Date().getTime() - 86400000 * 30);
    return {
      cardLists: [
        { title: "应到岗", number: 0, unit: "人" },
        { title: "打卡", number: 0, unit: "人" },
        { title: "未打卡", number: 0, unit: "人" },
        { title: "迟到/早退", number: 0, unit: "人" },
      ],
      defalutDate: [],
      upData: {
        "Session-Id": sessionStorage.token,
      },
      dialogVisible: false,
      fileList: [],
      fileName: "",
      pName: "",
      selection: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      sexList: [],
      allManageState: [],
      parkList: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableCols: [
        {
          prop: "pdaManagerName",
          label: this.$t("list.pda_Manager_Name"),
          width: "",
        },
        {
          prop: "pdaManagerCode",
          label: this.$t("list.pda_Manager_Code"),
          width: "",
        },
        {
          prop: "type",
          label: this.$t("list.role"),
          width: "",
          formatter: (row, column) => {
            let obj = {
              1: this.$t("list.Toll_collector"),
              2: this.$t("list.Inspector"),
            };
            return obj[row.type];
          },
        },
        //        {
        //          prop: "deptName",
        //          label: "小队",
        //          width: "",
        //        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
      ],
      teamList: [],
      tableData: [],
      timeType: "daterange",
      searchTime: [yesterday, today],
      tenantList: [],
      formInline: {
        month: dateFormat(new Date(), "yyyy-MM"),
        pdaManagerName: "",
        pdaManagerCode: "",
        roleId: "",
        dateType: "1",
        startDate: "",
        endDate: "",
        operationId: "",
        orderByName: "",
        orderByType: "",
      },
      pickerOptions: {
        // 当天和当天以后设置为灰
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      attendanceTotal: {},
    };
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    exportFile() {
      // /attend/attendance/export
      let opt = {
        pdaManagerName: this.formInline.pdaManagerName,
        pdaManagerCode: this.formInline.pdaManagerCode,
        operationId: this.formInline.operationId,
        roleId: this.formInline.roleId,
        dateType: this.formInline.dateType,
        startDate: this.formInline.startDate,
        endDate: this.formInline.endDate,
        orderByType: this.formInline.orderByType,
        orderByName: this.formInline.orderByName,
      };
      let flag = this.showlog();
      if (flag) {
        if (!this.formInline.month) {
          this.$alert("月份不能为空！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        exportExcelNew("/acb/2.0/pdaManager/attend/attendance/export", opt);
      }
    },
    timeTypeChange() {
      if (this.formInline.dateType === "2") {
        this.timeType = "monthrange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime());
        endTime.setTime(endTime.getTime());
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startDate = this.searchTime[0];
        this.formInline.endDate = this.searchTime[1];
      } else {
        this.timeType = "daterange";
        let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
        const startTime = new Date();
        const endTime = new Date();
        startTime.setTime(startTime.getTime() - 30 * 24 * 60 * 60 * 1000);
        endTime.setTime(endTime.getTime());
        this.searchTime[0] = dateFormat(startTime, tmp);
        this.searchTime[1] = dateFormat(endTime, tmp);
        this.formInline.startDate = this.searchTime[0];
        this.formInline.endDate = this.searchTime[1];
      }
    },
    getTeamList() {
      // /acb/2.0/dept/dept/getByLevel/{level}
      // this.$axios.get("/acb/2.0/dept/getByLevel/3").then((res) => {
      // this.teamList = res.value;
      // });
    },
    searchTimeChange() {
      let tmp = this.formInline.dateType === "1" ? "yyyy-MM-dd" : "yyyy-MM";
      this.formInline.startDate = dateFormat(this.searchTime[0], tmp);
      this.formInline.endDate = dateFormat(this.searchTime[1], tmp);
    },
    clearForm() {
      this.formInline.team = "";
      this.$refs.searchForm.resetFields();
      // 初始化日期
      this.$refs.timeRangePicker.resetTime();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({
          path: "/collectorAE",
          query: data,
        });
      }
      if (cmd == "b") {
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    picChange(file, row) {
      if (file.name && !file.response) {
        this.fileName = file.name;
        this.picFlag = true;
      } else {
        this.$refs.upload.clearFiles();
        this.fileName = "";
        this.picFlag = false;
      }
    },
    submitUpload() {
      if (this.picFlag) {
        this.$refs.upload.submit();
      } else {
        this.$alert("请选择需要上传的文件", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    beforeUpload() {
      this.dialogVisible = true;
      this.picFlag = false;
      this.fileName = "";
      this.$refs.upload.clearFiles();
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res.state == 0) {
        this.$message({
          type: "info",
          message: "文件上传成功",
        });
        this.dialogVisible = false;
        this.pageNum = 1;
        this.searchData();
      } else {
        this.$alert(res.value + " 请重新选择文件！！！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 时间校验
    showlog(tag) {
      //   if (this.formInline.dateType === "1") {
      //     let data1 = Date.parse(this.formInline.startDate.replace(/-/g, "/"));
      //     let data2 = Date.parse(this.formInline.endDate.replace(/-/g, "/"));
      //     let datadiff = data2 - data1;
      //     let time = 31 * 24 * 60 * 60 * 1000;
      //     if (datadiff > time) {
      //       this.$alert("时间不能超过31天", "提示");
      //       return false;
      //     }
      //   } else if (this.formInline.dateType === "2") {
      //     let data1 = Date.parse(this.formInline.startDate.replace(/-/g, "/"));
      //     let data2 = Date.parse(this.formInline.endDate.replace(/-/g, "/"));
      //     let datadiff = data2 - data1;
      //     let time = 360 * 24 * 60 * 60 * 1000;
      //     if (datadiff > time) {
      //       this.$alert("搜索时间不能超过12个月", "提示");
      //       return false;
      //     }
      //   }
      if (!this.formInline.startDate || !this.formInline.endDate) {
        this.$alert("日期不能为空", "提示");
        return false;
      } else {
        return true;
      }
    },
    handleSortChange(value) {
      console.log(value);
      //   delete this.listQuery.locOrder;
      //   delete this.listQuery.updateTimeOrder;
      if (value.prop && value.order) {
        this.formInline.orderByName = value.prop;
        this.formInline.orderByType = value.order === "ascending" ? 0 : 1;
        console.log(
          this.formInline.orderByName,
          this.formInline.orderByType,
          "this.orderByName"
        );
      }
      this.searchData();
    },
    // 搜索
    searchData() {
      let flag = this.showlog();
      if (flag) {
        if (!this.formInline.month) {
          this.$alert("月份不能为空！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.loading = true;
        this.tableData = [];
        this.attendancePageStatistics();
        let url = "/acb/2.0/pdaManager/attend/attendance/page";
        this.$axios
          .get(url, {
            data: {
              page: this.page,
              pageSize: this.pageSize,
              pdaManagerName: this.formInline.pdaManagerName,
              pdaManagerCode: this.formInline.pdaManagerCode,
              roleId: this.formInline.roleId,
              dateType: this.formInline.dateType,
              startDate: this.formInline.startDate,
              endDate: this.formInline.endDate,
              operationId: this.formInline.operationId,
              orderByType: this.formInline.orderByType,
              orderByName: this.formInline.orderByName,
            },
          })
          .then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    attendancePageStatistics() {
      let url = "/acb/2.0/pdaManager/attend/attendancePageStatistics";
      this.$axios
        .get(url, {
          data: {
            pdaManagerName: this.formInline.pdaManagerName,
            pdaManagerCode: this.formInline.pdaManagerCode,
            roleId: this.formInline.roleId,
            dateType: 1,
            startDate: this.formInline.startDate,
            endDate: this.formInline.endDate,
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            console.log(res.value, "kkkkkkkkkkkkk");
            this.cardLists[0].number = res.value.sumScheduleCount;
            this.cardLists[1].number = res.value.sumAttendTypeOn;
            this.cardLists[2].number = res.value.sumLackDay;
            this.cardLists[3].number = res.value.sumExceptDay;
            // this.attendanceTotal = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.startDate = timeArr[0];
      this.formInline.endDate = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
  },
  components: {
    timeRangePick,
    tabCard,
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  created() {
    this.getTenantList();
    // this.defalutDate = [
    //   this.dataTimeRest() + " 00:00:00",
    //   this.dataTimeRest() + " 23:59:59",
    // ];
  },
  mounted() {
    this.getTeamList();
    this.formInline.startDate = dateFormat(this.searchTime[0], "yyyy-MM-dd");
    this.formInline.endDate = dateFormat(this.searchTime[1], "yyyy-MM-dd");
    this.defalutDate = [
      dateFormat(this.searchTime[0], "yyyy-MM-dd") + " 00:00:00",
      dateFormat(this.searchTime[1], "yyyy-MM-dd") + " 23:59:59",
    ];
    console.log("-->this.defalutDate", this.defalutDate);
    this.searchData();
  },
  activated() {
    // console.log(this.searchTime)
    let today = new Date(new Date().getTime());
    let yesterday = new Date(new Date().getTime() - 86400000 * 30);
    this.searchTime = [yesterday, today];
    this.timeTypeChange();
    this.searchData();
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.pdaManagerName,
          warntip: "请输入您查询的收费员",
        },
      ];
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}

.totalNum {
  display: inline-block;
  background-color: #eee;
  margin-right: 50px;
  width: 150px;
  height: 50px;
  text-align: center;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.totalNum div:nth-of-type(2) {
  margin-top: 10px;
}

.totalNum div:nth-of-type(2) span {
  font-size: 30px;
}
</style>
<style scoped>
/* .el-button {
  margin: 0 !important;
} */
</style>
